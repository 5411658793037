import React from "react";
import { Link, graphql } from "gatsby";
import PropTypes from "prop-types";
import Cta from "../components/cta";

import Layout from "../components/layout";
import { renderRichText } from "gatsby-source-contentful/rich-text";

export const query = graphql`
    query ($slug: String!) {
        post: contentfulSellerStoryPost(slug: { eq: $slug }) {
            title
            slug
            featuredImage
            featuredVideo
            content {
                raw
            }
            shopName
            shopOwner
        }
    }
`;

const PostTemplate = ({ data: { post } }) => (
    <Layout>
        <section className="">
            <div className="w-full max-w-4xl mx-auto px-4 py-8 md:px-8 md:py-16">
                <div className="flex w-full mb-6">
                    <Link
                        to={`../seller-story`}
                        className="mx-auto border border-gray-600 rounded-full py-1 px-5 text-center"
                    >
                        &larr; All Stories
                    </Link>
                </div>
                <h2 className="font-display mb-3 text-4xl text-center leading-tight">
                    {post.title}
                </h2>

                {post.featuredVideo !== null ? (
                    <div className="embed-container mt-10">
                        <iframe
                            src={`${post.featuredVideo}?autoplay=0`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                    </div>
                ) : null}

                {post.featuredImage !== "" ? (
                    <img src={post.featuredImage} alt="" className="my-10" />
                ) : null}

                <div className="post">{renderRichText(post.content)}</div>
            </div>
        </section>

        <Cta />
    </Layout>
);

PostTemplate.propTypes = {
    data: PropTypes.object,
};

export default PostTemplate;
